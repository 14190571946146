import React from 'react';

export default class RequestAnAgent extends React.Component{
  render(){
    return(
      <div>
        <h1 style= {{padding:"100px"}}>Request An Agent</h1>
      </div>
    );
  }
}
