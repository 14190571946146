import React from 'react';
import './App.css';
import Header from './components/header';
import Dashboard from './components/dashboard';
import MyHomeScope from './components/my-homescope';
import RequestAnAgent from './components/request-an-agent';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';


class App extends React.Component {
  render() {
    return (
      <Router>
        <div>
          <Header />
          <Switch>
            <Route path="/" exact component={Dashboard} />
            <Route path="/myhomescope" exact component={MyHomeScope} />
            <Route path="/requestanagent" exact component={RequestAnAgent}/>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;

// function App() {
//   return (
//     <Router>
//       <div className="App">
//         <Navigation />
//         <Switch>
//           <Route path="/" exact component={Home} />
//           <Route path="/about" component={About} />
//           <Route path="/shop" exact component={Shop} />
//           <Route path="/shop/:id" component={ItemDetails} />
//         </Switch>
//       </div>
//     </Router>
//   );
// }
